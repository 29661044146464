.support {
  position: relative;

  padding: 2.5rem 1.2rem 3rem;
  background-color: $lite-grey;

  @media (min-width: 992px) {
    padding: 0px;

    background-color: $lite-grey;
  }
  @media (min-width: 1440px) {
    padding: 0px;

    background-color: $lite-grey;
  }

  h2 {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    text-align: center;

    color: #6b5eb0;
    //max-width: 559px;
    @media (min-width: 992px) {
      display: none;
      font-size: 28px;
      text-align: left;
    }
  }

  .container-supp {
    width: 100%;
    margin: 0 auto;
    @media (min-width: 992px) {
      max-width: 1140px;
      display: grid;
      grid-template-columns: 39% auto;
    }
  }

  .container-info {
    display: flex;
    width: auto;
    flex-direction: column;
    justify-content: center;

    @media (min-width: 992px) {
      justify-content: center;
    }

    > h2 {
      display: none;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 36px;

      color: #6b5eb0;
      @media (min-width: 992px) {
        display: block;
        width: 491px;
        margin-left: 8%;
      }
    }
    .container-points {
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media (min-width: 992px) {
        flex-direction: row;
        justify-content: space-around;
      }
      .section-one {
        .point {
          @media (min-width: 992px) {
            width: 238px;
          }
        }
      }
      .section-two {
        .point {
          width: 208px;
        }
      }
    }
    .point {
      display: flex;
      flex-direction: row;
      padding: 0 2rem;
      @media (min-width: 992px) {
      }
      > img {
        object-fit: contain;

        align-self: baseline;
        margin-top: 18px;
      }
      .text {
        padding-left: 1rem;
        .title {
          font-family: Inter;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 22px;
          color: #1f313d;
          @media (min-width: 992px) {
            margin-bottom: 0;
          }
        }
        > p {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 15px;

          color: #1f313d;
        }
      }
    }
    @media (min-width: 992px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
    }
  }

  .container-img {
    display: none;
    @media (min-width: 992px) {
      display: block;
      position: absolute;
      top: 5%;
      right: 0;
    }
    img {
      object-fit: contain;
      position: absolute;
      right: 0;
      @media (min-width: 1440px) {
      }
      @media (min-width: 992px) {
        width: 24vw;
        top: -28px;
      }
    }
  }
  .carousel-root {
    // margin: 100px auto -2px;

    @media (min-width: 992px) {
      margin-bottom: -7vw;
      // margin: 100px auto -100px;
      margin: 7vw auto -7vw;
    }
    @media (min-width: 1440px) {
      margin: 100px auto -100px;
    }

    .carousel {
      .control-arrow {
        display: none !important;
      }
      .control-dots {
        display: none !important;
      }
      .carousel-status {
        display: none !important;
      }
    }
    .slide {
      background: transparent !important;
      .control-dots {
        display: none !important;
        @media (min-width: 992px) {
          display: none !important;
        }
      }
      .carousel-status {
        display: none !important;
        @media (min-width: 992px) {
          display: none !important;
        }
      }
    }
  }
}

.testimonial{
    padding: 64px 20px 51px 20px;
    background-image: url('../assets/testimonial-bg-rect.png');
    background-size: cover;
    background-position: 80% 50%;
    text-align: center;
    overflow-x: hidden;
    @media (min-width: 992px) {
        padding: 130px 20px 90px 20px;
        background-image: url('../assets/testimonial-bg-rect.png');
        overflow-y: hidden;
        
        
    }
    .container{
        max-width: 320px;
        width: 100%;
        margin: 0 auto;
        @media (min-width: 992px) {
            max-width: 900px;
        }
    }
    .img-sign{
        width: 53px;
        display: inline-block;

    }
    p{
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 31px;
        text-align: center;
        color: #FFFFFF;
        margin: 68px auto;
        @media (min-width: 992px) {
            font-size: 26px;
            margin: 67px auto 63px;
            
        }
        
    }
    .container-img{
        >img{
            
            border-radius: 50%;
            overflow: hidden;
            width: 94px;
            height: 94px;
            object-fit: cover;
            object-position: center;
        }
        >p{
            font-family: Open Sans;
            font-style: normal;
            font-weight: bold;
            font-size: 17px;
            line-height: 112.5%;
            
            text-align: center;

            color: #FFFFFF;
            margin: 0 auto;
        }

    }
}




.container-privacy {
  padding: 1rem;
  font-family: Archivo;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  //line-height: 63px;
  text-align: justify;

  color: #424b5a;
  margin: 0 10px;
  @media (min-width: 992px) {
    margin: 0 50px;
  }
}

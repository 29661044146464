@font-face {
    font-family: 'Inter';
    src: url("../assets/fonts/Inter-Medium-slnt=0.ttf") format("truetype");
  }

  @font-face {
    font-family: 'Inter';
    font-weight: bold;
    src: url("../assets/fonts/Inter-Bold-slnt=0.ttf") format("truetype");
  }

  @font-face {
    font-family: 'Open Sans';
    src: url("../assets/fonts/OpenSans-Regular.ttf") format("truetype");
  }

  @font-face {
    font-family: 'Open Sans';
    font-weight: medium;
    src: url("../assets/fonts/OpenSans-SemiBold.ttf") format("truetype");
  }

  @font-face {
    font-family: 'Open Sans';
    font-weight: bold;
    src: url("../assets/fonts/OpenSans-Bold.ttf") format("truetype");
  }

  @font-face {
    font-family: 'Archivo';
    src: url("../assets/fonts/Archivo-Regular.ttf") format("truetype");
  }

  @font-face {
    font-family: 'Archivo';
    font-weight: bold;
    src: url("../assets/fonts/Archivo-Bold.ttf") format("truetype");
  }

  $font-inter: 'Inter', Helvetica, Arial;
  $font-open: 'Open', Helvetica, Arial;
  $font-archivo: 'Archivo', Helvetica, Arial;

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 22rem;
  background-color: #6b5eb0;

  bottom: 0;
  
  
  @media (min-width: 992px) {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 14rem;
  }
  .download {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1{
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 112.5%;
      text-align: center;
      color: #ffffff;
      @media (min-width: 992px) {
        align-self: flex-end;
        margin-right: 15px;
      }
    }
  }
  .container-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
  
  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0.5rem;
  
    cursor: pointer;
    @media (min-width: 992px) {
      padding: 1rem;
    }
  }
  
  img {
    max-width: 8rem;
    object-fit: contain;
  }
  
  .pnky-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    h1{
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 112.5%;
      text-align: center;
      color: #ffffff;
      

    }
    p{
     font-family: Inter;
     font-style: normal;
     font-weight: normal;
     font-size: 14px;
     line-height: 28px;
     text-align: center;
     color: #F4F6F9;
     margin: 0;
     text-decoration: none;
        
    }
    a{
     text-decoration: none;

    }
  
    @media (min-width: 992px) {
      align-items: flex-start;
      text-decoration: none;

      
    }
  }
}



.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  
  background-color: #ffffff;

  background-image: url("../assets/hero-bg.png");
  background-size: cover;
  background-position: center;
  
  
  @media (min-width: 992px) {
    flex-direction: row;
    
    
    background-position: bottom;
    

    
  }

  .container-hero{
      display: flex;
      flex-direction: column;
      
      @media (min-width: 1440px) {
        margin-right: 227px;
      }
  }
  
  
  h1 {
    font-family: Archivo;
    font-style: normal;
    font-weight: bold;
    font-size: 45px;
    line-height: 57px;
    text-align: center;
    color: #ffffff;
    margin: 35px 20px;

    @media (min-width: 992px) {
      font-size: 50px;
      align-self: start;
      margin-bottom: 35px;
      margin-left: 0;
      max-width: 360px;
      font-size: 58px;
      text-align: left;
      margin-bottom: 35px;

      
    }
  }
  p {
    font-family: Archivo;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;

    margin: 0 25px 0 25px;
    
    text-align: center;
    color: #ffffff;
    @media (min-width: 992px) {
      font-size: 22px;
      line-height: 30px;
      text-align: left;
      max-width: 409px;
      margin-left: 0;
      margin-bottom: 30px;
      //margin: 0 25px 0 72px;
    }
  }
  .container-buttons-hero {
    display: flex;
    flex-direction: row;
    //align-items: center;
    justify-content: center;
    margin: 66px 0;
    @media (min-width: 992px) {
      align-self: baseline;
      margin: 0;
      //margin-left: 0;
    }
    .appStore-hero{
        
        @media (min-width: 992px) {
            padding-left: 0;
            margin-left: 0;

      }

    }
  }

  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0.5rem;
    cursor: pointer;

    @media (min-width: 992px) {
      padding: 1rem;
    }

    img {
      width: 116px;
      object-fit: contain;
      @media (min-width: 992px) {
      width: 176px;
      }
    }
  }

  .img-phone {
    display: none;
   
        
    @media (min-width: 992px) {
        
        display: flex;
        align-self: baseline;
        position: relative;
        top: 68px;
        
        
        object-fit: cover;
        object-position: right;
        
        
    }
  }

}

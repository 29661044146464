@import "_typo";
@import "_variables";
@import "_header";
@import "_footer";
@import "_agreement";
@import "_hero";
@import "_testimonial";
@import "_letstalk";
@import "_support";
@import "_privacy";
@import "_terms";


body {
    margin: 0;
    overflow-x: hidden;
    
}

::-webkit-scrollbar {
    display: none;
}




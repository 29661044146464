.talk {
  padding: 64px 20px 51px 20px;
  background-image: url("../assets/contact-bg-rect.png");
  background-size: cover;
  background-position: 20% 100%;
  text-align: center;
  position: relative;
  overflow-x: hidden;
  @media (min-width: 992px) {
    //padding: 130px 20px 90px 20px;
    background-image: url("../assets/contact-bg-rect.png");
  }
  .container-talk {
    max-width: 320px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    @media (min-width: 992px) {
      max-width: 900px;

      flex-direction: row;
    }
    .container-text {
      max-width: 450px;
      padding: 0.5rem;
      // text-align: initial;
      display: flex;

      flex-direction: column;
      justify-content: center;
      > h1 {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 36px;
        color: $purple;
        text-align: left;
      }
      > p {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        color: #1f313d;
        text-align: left;
      }
      .emailtalk {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 29px;
        text-decoration-line: underline;
        color: #ffb662;
        text-align: left;
        cursor: pointer;
        @media (min-width: 992px) {
          font-size: 24px;
          max-width: 425px;
        }
      }
    }
  }

  .container-imgtalk {
    > img {
      overflow: hidden;
      width: 90%;

      //margin-bottom: 20px;

      @media (min-width: 992px) {
        width: 45vw;
        margin-right: -20vw;
      }
      @media (min-width: 1440px) {
        width: auto;
        margin-right: -20vw;
      }
    }
  }
}

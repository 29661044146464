.container-all {
  overflow-x: hidden;
  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: 20% 60% 20%;
    overflow-y: hidden;
  }
  .left-container {
    display: none;
    > img {
      @media (min-width: 992px) {
        width: 98%;
      }
    }
    @media (min-width: 992px) {
      display: flex;
      align-items: center;
    }
  }
  .right-container {
    display: none;
    > img {
      @media (min-width: 992px) {
        width: 98%;
      }
    }

    @media (min-width: 992px) {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.agreement {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  width: 100%;
  height: auto;
  @media (min-width: 992px) {
    padding: 100px 0;
  }

  .intro-agreement {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (min-width: 992px) {
      width: 693px;
    }

    > h1 {
      padding: 0 2rem 0 2rem;
      text-align: center;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 36px;
      text-align: center;

      color: $purple;
      @media (min-width: 992px) {
        font-size: 30px;
      }
    }
    > p {
      padding: 0 2rem 0 2rem;
      text-align: center;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;

      text-align: center;

      color: #1f313d;
    }
  }

  .section-issues {
    display: flex;
    flex-direction: row;
    @media (min-width: 992px) {
      flex-direction: column;
    }
  }

  .container-icons {
    display: flex;
    flex-direction: column;
    //margin-left: 1rem;

    @media (min-width: 992px) {
      flex-direction: row;
      margin-top: 50px;
    }

    .container-issue {
      display: flex;
      flex-direction: row;
      align-items: center;

      padding: 1rem;
      > p {
        padding-left: 1rem;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        color: #1f313d;
        @media (min-width: 992px) {
          width: 218px;
          text-align: center;
        }
      }
      @media (min-width: 992px) {
        flex-direction: column;
        align-items: center;
      }
    }
  }

  .container-hand {
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
    @media (min-width: 992px) {
      display: none;
    }
    .right-hand {
      float: right;
      width: 100%;
      max-width: 250px;
    }
    .left-hand {
      width: 100%;
      max-width: 250px;
    }
  }
}

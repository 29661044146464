.header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  
  background-color: $background;
  

  @media (min-width: 992px) {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .title-header {
    padding-left: 0;
    font-family: $font-open;
    @media (min-width: 992px) {
      padding-left: 2rem;
    }
  }
  
  a {
    color: white;
    text-decoration: none;
    cursor: pointer;
  
  }
}


